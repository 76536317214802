<template>
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.84681 0C3.173 0 3.46954 0.263672 3.52885 0.585938L3.58816 0.9375H16.0429C16.6657 0.9375 17.1401 1.55273 16.9622 2.13867L15.3609 7.76367C15.2423 8.17383 14.8864 8.4375 14.4416 8.4375H5.04122L5.30811 9.84375H14.4713C14.8568 9.84375 15.183 10.166 15.183 10.5469C15.183 10.957 14.8568 11.25 14.4713 11.25H4.71502C4.38882 11.25 4.09228 11.0156 4.03297 10.6934L2.25372 1.40625H0.711701C0.296542 1.40625 0 1.11328 0 0.703125C0 0.322266 0.296542 0 0.711701 0H2.84681ZM3.79574 13.5938C3.79574 12.832 4.41848 12.1875 5.21914 12.1875C5.99015 12.1875 6.64255 12.832 6.64255 13.5938C6.64255 14.3848 5.99015 15 5.21914 15C4.41848 15 3.79574 14.3848 3.79574 13.5938ZM15.183 13.5938C15.183 14.3848 14.5306 15 13.7596 15C12.9589 15 12.3362 14.3848 12.3362 13.5938C12.3362 12.832 12.9589 12.1875 13.7596 12.1875C14.5306 12.1875 15.183 12.832 15.183 13.5938Z"
      :fill="props.fill"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: String,
    required: false,
    default: 'white'
  },
});
</script>
